import React from 'react';
import styled from 'styled-components';
import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';

const SErrorMessage = styled.p`
  color: #bf1650;
  font-size: 16px;
  margin: 0;

  &:before {
    display: inline;
    content: '⚠ ';
  }
`;

const FormErrorMessage = ({ errors, name }) => {
  return <ErrorMessage errors={errors} name={name} as={<SErrorMessage />} />;
};

FormErrorMessage.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string,
};

export default FormErrorMessage;
