import React, { useContext } from 'react';

import AlertMessage from 'components/AlertMessage';
import Button from 'components/Button';
import FormErrorMessage from 'components/FormErrorMessage';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useAxios } from 'hooks/useAxios';
import { navigate } from 'gatsby';
import { UserContext } from '../../wrap-with-provider';

const LoginForm = () => {
  const { setUser } = useContext(UserContext);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    criteriaMode: 'all',
  });
  const [{ loading, error }, refetch] = useAxios(
    {
      url: 'api/auth/login/',
      method: 'POST',
    },
    { manual: true },
  );

  const onSubmit = async values => {
    console.log(values);
    await refetch({
      data: {
        email: values.email,
        password: values.password,
      },
    })
      .then(rsp => {
        console.log('response :>> ', rsp.data);
        setUser(rsp.data.data);
        rsp.data.data.user_type === 'Landlord'
          ? navigate('/account/')
          : navigate('/');
      })
      .catch(error => console.error(error));
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} id="login-form">
        <Form.Group controlId="loginFormEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            name="email"
            type="email"
            placeholder="Enter email"
            {...register('email', {
              required: 'This field is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            })}
          />
          {/* <Form.Text className="text-muted">
                We&apos;ll never share your email with anyone else.
              </Form.Text> */}
          <FormErrorMessage errors={errors} name="email" />
        </Form.Group>

        <Form.Group controlId="loginFormPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            name="password"
            type="password"
            placeholder="Password"
            {...register('password', {
              required: 'This field is required',
            })}
          />
          <FormErrorMessage errors={errors} name="password" />
        </Form.Group>
        {error && (
          <AlertMessage
            errorResponse={error}
            message={error.response.data.message}
          />
        )}
        <Button
          className="w-100 my-4"
          variant="full"
          type="submit"
          color="green"
          buttonID="login-button"
        >
          {loading ? 'Loading...' : 'Submit'}
        </Button>
      </Form>
      {/* {process.env.NODE_ENV === 'production' ? null : (
        <DevTool control={control} placement="top-end" />
      )} */}
    </>
  );
};

export default LoginForm;
