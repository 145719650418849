import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SAlert } from './styles';

const AlertMessage = ({ errorResponse, successResponse, message, heading }) => {
  const [show, setShow] = useState(true);
  //prettier-ignore
  const variant = errorResponse ? "danger" : successResponse ? "success" : "warning";
  if (show) {
    return (
      <SAlert variant={variant} onClose={() => setShow(false)} dismissible>
        {heading && <SAlert.Heading>{heading}</SAlert.Heading>}
        {message}
      </SAlert>
    );
  }
  return null;
};
AlertMessage.propTypes = {
  errorResponse: PropTypes.object,
  successResponse: PropTypes.object,
  message: PropTypes.string,
  heading: PropTypes.string,
};

export default AlertMessage;
