import { calcResize } from 'components/Layout/styles';
import styled from 'styled-components';

export const SDifferentRoute = styled.div`
  text-align: center;
  font-size: 16px;
  margin-top: 5px;
`;

export const SH1 = styled.h1`
  font-size: ${calcResize(28, 30)};
`;
