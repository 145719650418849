/* eslint-disable react/prop-types */
import { Link } from 'gatsby';
/**
 *
 * LoginPage
 *
 */

import React, { useContext, useEffect, useState } from 'react';
import Layout from 'components/Layout';
import SplitSectionWithImage from 'containers/SplitSectionWithImage';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import { UserContext } from '../../wrap-with-provider';
import { SDifferentRoute, SH1 } from './styles';
import LoginForm from './_form';
import AlertMessage from 'components/AlertMessage';
import SEO from 'components/seo';

export const LoginPage = props => {
  const { user } = useContext(UserContext);

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const queryParams = queryString.parse(props.location.search);

  useEffect(() => {
    if (user && user !== null) {
      navigate('/');
    }

    if (props.location.state && props.location.state.success) {
      setSuccessMessage(props.location.state.success);
    }
    if (queryParams) {
      if (queryParams.activate === '1') {
        setSuccessMessage(
          'Your email address was successfully verified. You can login now.',
        );
      } else if (queryParams.activation_expired === '1') {
        setErrorMessage(
          'Your activation link expired, please contact administration.',
        );
      }
    }
  }, []);

  return (
    <Layout navType="dark" navBgColor="#fff" navLinkType="dark">
      <SEO
        title="Login to your Landlord or Tenant Account"
        description="Log in or sign up to an Oasis Living account today. Discover how we make property letting and managment easier and more efficient by using proptech solutions"
      />
      <SplitSectionWithImage
        imageName="mobile-user.png"
        //imageAlt="Image Alt"
        isImageLeft={true}
      >
        <SH1 className={'mb-5'}>
          Log in to Oasis Living platform and start managing your property
        </SH1>
        {successMessage && (
          <AlertMessage
            errorResponse={errorMessage}
            successResponse={successMessage}
            message={successMessage}
          />
        )}
        <LoginForm />
        <SDifferentRoute>
          Don&apos;t have an account?&nbsp;
          <Link to="/register/">Create one</Link>
        </SDifferentRoute>
      </SplitSectionWithImage>
    </Layout>
  );
};

LoginPage.propTypes = {};

export default LoginPage;
